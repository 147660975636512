<template>
  <div class="horizontal-layout horizontal-menu navbar-static 1-column   footer-static bg-full-screen-image  blank-page"
       data-open="hover" data-menu="horizontal-menu" data-col="1-column">
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="content-wrapper">
        <div class="content-body">
          <!-- error 403 -->
          <section class="row flexbox-container">
            <div class="col-xl-6 col-md-7 col-9">
              <div class="card bg-transparent shadow-none">
                <div class="card-body text-center bg-transparent">
                  <img :src="img" class="img-fluid my-3" alt="branding logo">
                  <h1 class="error-title mt-1"><span>We are sorry!</span></h1>
                  <p class="p-2">
                    <span>The page you're to access has restricted access</span>
                    <br>
                    <span>Please, refer to your system administrator</span>
                  </p>
                  <router-link :to="{name:'appDashboard'}">
                    <button type="button" class="btn btn-primary round glow mt-3">
                      <span class="align-middle ml-25">GO BACK TO WHERE YOU WERE</span>
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </section>
          <!-- error 403 end -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "404",
  data() {
    return {
      img: '/app-assets/images/pages/403.png'
    }
  },
  
}
</script>
